import React from 'react';
import styles from './contact.module.css';

const Contact: React.FC = () => {
  return (
    <div className={styles.contactusContainer}>
      <h1>Contact Us</h1>
      <p>
        If you have any questions about our app or our company, please visit our Frequently Asked Questions (FAQ) <a href="/faq">section</a>.
        <br />
        <br />
        To contact us, please email us at <a href="mailto:support@phienx.com">support@phienx.com</a> and we will get back to you as soon as we can.
      </p>
    </div>
  );
};

export default Contact;

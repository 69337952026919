// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**********************************************
 ****           get app page css           ****
 **********************************************/
.getapp_contactusContainer__ObQD\\+ {
  position: relative;
  color: #e6fcf8;
  width: 100%;
  margin: 0 auto;
  margin-top: 3vh;
  min-height: 76vh;
}

.getapp_contactusContainer__ObQD\\+ h1 {
  color: #caf8ea;
  text-align: center;
  font-size: 1.7rem;
}

.getapp_contactusContainer__ObQD\\+ p {
  color: #e6fcf8;
  text-align: center;
  font-size: 1rem;
}

.getapp_contactusContainer__ObQD\\+ a {
  color: #16B99E;
  transition: color 0.4s ease;
  display: inline-block;
  font-weight: 700;
}

.getapp_contactusContainer__ObQD\\+ a:hover {
  animation: getapp_bounceUpDown__mgrys 0.4s ease;
  color: #aaf8ea;
}

@keyframes getapp_bounceUpDown__mgrys {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/getapp/getapp.module.css"],"names":[],"mappings":"AAAA;;+CAE+C;AAC/C;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,+CAAiC;EACjC,cAAc;AAChB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["/**********************************************\n ****           get app page css           ****\n **********************************************/\n.contactusContainer {\n  position: relative;\n  color: #e6fcf8;\n  width: 100%;\n  margin: 0 auto;\n  margin-top: 3vh;\n  min-height: 76vh;\n}\n\n.contactusContainer h1 {\n  color: #caf8ea;\n  text-align: center;\n  font-size: 1.7rem;\n}\n\n.contactusContainer p {\n  color: #e6fcf8;\n  text-align: center;\n  font-size: 1rem;\n}\n\n.contactusContainer a {\n  color: #16B99E;\n  transition: color 0.4s ease;\n  display: inline-block;\n  font-weight: 700;\n}\n\n.contactusContainer a:hover {\n  animation: bounceUpDown 0.4s ease;\n  color: #aaf8ea;\n}\n\n@keyframes bounceUpDown {\n  0% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(-1px);\n  }\n  100% {\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactusContainer": `getapp_contactusContainer__ObQD+`,
	"bounceUpDown": `getapp_bounceUpDown__mgrys`
};
export default ___CSS_LOADER_EXPORT___;

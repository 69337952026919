import React, { useState, useEffect } from 'react';
import faqQuestions from './faq_questions.json';
import QuestionPopup from './QuestionPopup';
import styles from './faq.module.css';

interface FAQQuestion {
  id: string;
  subject: string;
  question: string;
  title: string;
  content: string;
}

interface QuestionProps {
  question_id: string;
}

const Question: React.FC<QuestionProps> = ({ question_id }) => {
  const [questionData, setQuestionData] = useState<FAQQuestion | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const data = (faqQuestions as Record<string, Omit<FAQQuestion, 'id'>>)[question_id];
    if (data) {
      setQuestionData({ id: question_id, ...data });
    } else {
      console.error(`No question found for id ${question_id}`);
    }
  }, [question_id]);

  const openPopup = () => setIsPopupOpen(true);

  if (!questionData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={styles.questionBox} onClick={openPopup}>
        <h4>{questionData.subject}</h4>
        <div className={styles.questionTitle}>{questionData.question}</div>
      </div>
      {isPopupOpen && (
        <QuestionPopup
          questionData={questionData}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </>
  );
};

export default Question;


import React from "react";
import { Link } from "react-router-dom";
import 'animate.css';
import styles from './navbar.module.css';

interface NavLink {
  title: string;
  url: string;
}

// Array of buttons+urls displayed on the navbar
const NAV_LINKS: NavLink[] = [
  { title: "Get App", url: "/get" },
  { title: "About", url: "/about" },
  { title: "FAQ", url: "/faq" },
  { title: "Contact", url: "/contact" },
];

const NavLinks: React.FC = () => {
  return (
  <ul className={styles.navItems}>
    {NAV_LINKS.map((link, index) => (
      <li key={index} className={`${styles.navItem} animate__animated animate__pulse`}>
        <Link to={link.url} className={styles.navLink}>
          {link.title}
        </Link>
      </li>
    ))}
  </ul>
  ) 
}

// Home Icon Component
const Home: React.FC = () => {
  return (
    <div className={styles.home + " animate__animated animate__pulse"}>
      <img src="/images/logo.png" alt="Logo" className={styles.homeIcon} />
      <span>
        <Link to="/">Home</Link>
      </span>
    </div>
  );
};

// Navbar Component
const Navbar: React.FC = () => {
  return (
    <nav className={styles.navbar}>
      <Home />
      <NavLinks />
    </nav>
  );
};

export default Navbar;


// AboutPage.tsx

import React, { useState, useEffect } from 'react';
import Legal from './Legal';
import About from './About';
import styles from './about.module.css';

interface AboutPageProps {
  defaultSection?: 'About' | 'Legal';
}

const AboutPage: React.FC<AboutPageProps> = ({ defaultSection = 'About' }) => {
  const [activeComponent, setActiveComponent] = useState<'About' | 'Legal'>(defaultSection);

  useEffect(() => {
    setActiveComponent(defaultSection);
  }, [defaultSection]);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Legal':
        return <Legal />;
      case 'About':
      default:
        return <About />;
    }
  };

  return (
    <div className={styles.aboutContainer}>
      <nav className={styles.navbar}>
        <button onClick={() => setActiveComponent('About')}>About</button>
        <button onClick={() => setActiveComponent('Legal')}>Legal</button>
      </nav>
      <div className={styles.aboutContent}>{renderComponent()}</div>
    </div>
  );
};

export default AboutPage;


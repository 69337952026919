import React, { useState, useEffect } from 'react';
import styles from './faq.module.css';

interface QuestionPopupProps {
  questionData: {
    title: string;
    content: string;
    question: string;
  };
  onClose: () => void;
}

const QuestionPopup: React.FC<QuestionPopupProps> = ({ questionData, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

useEffect(() => {
  console.log('Popup mounted, disabling scroll'); // Log when mounted
  //document.body.style.overflow = 'hidden'; // Apply the hidden scroll
  
  // Debug: Log current style
  console.log('Current body style:', document.body.style.cssText);

  return () => {
    //console.log('Popup unmounted, enabling scroll'); // Log when unmounted
    document.body.style.overflow = 'auto'; // Restore scroll

    // Debug: Log current style after cleanup
    //console.log('Restored body style:', document.body.style.cssText);
  };
}, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <div
      className={`${styles.popupContainer} animate__animated ${
        isClosing ? 'animate__fadeOut' : 'animate__fadeIn'
      }`}
      style={{ animationDuration: '0.5s' }}
    >
      <div className={styles.popupContent}>
        <button className={styles.closeButton} onClick={handleClose}>
          X
        </button>
        <h2>{questionData.question}</h2>
        <div
          className={styles.popupScrollContent}
          dangerouslySetInnerHTML={{ __html: questionData.content }}
        />
      </div>
    </div>
  );
};

export default QuestionPopup;


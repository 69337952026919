// About.tsx

import React from 'react';
import styles from './about.module.css';

const Vision: React.FC = () => (
  <div className={styles.section}>
    <h2 className={styles.label}>Vision</h2>
    <p className={styles.content}>
      To be the premier platform for enhancing emotional intelligence through innovative and engaging gamified mental health solutions. We aim to create a global community where individuals securely share their health data, driving significant advancements in mental health research and contributing to societal well-being.
    </p>
  </div>
);

const Mission: React.FC = () => (
  <div className={styles.section}>
    <h2 className={styles.label}>Mission</h2>
    <p className={styles.content}>
      Our mission is to empower users to understand and manage their emotions through an engaging gamified AI experience. We provide innovative tools that enhance emotional intelligence, facilitate effective communication with therapists, and support broader health research to benefit society.
    </p>
  </div>
);

interface ValueItemProps {
  title: string;
  description: string;
}

const ValueItem: React.FC<ValueItemProps> = ({ title, description }) => (
  <div>
    <h3 className={styles.sublabel}>{title}</h3>
    <p className={styles.subcontent}>{description}</p>
  </div>
);

const Values: React.FC = () => (
  <div className={styles.section}>
    <h2 className={styles.label}>Values</h2>
    <div className={styles.content}>
      <ValueItem title="Do No Harm" description="Ensuring our system and data are never used in a manner that could harm individuals." />
      <ValueItem title="Privacy and Security" description="Prioritizing user privacy and maintaining the highest standards of data security." />
      <ValueItem title="Empowerment" description="Enabling users to take control of their emotional health and contribute positively to societal well-being." />
      <ValueItem title="Innovation" description="Leveraging AI to provide personalized emotional support and actionable insights." />
      <ValueItem title="Transparency" description="Keeping users informed about how their data is used and the positive impact it has on society." />
      <ValueItem title="Inclusivity" description="Embracing diversity and ensuring our solutions are accessible and beneficial to all." />
      <ValueItem title="User-Centric" description="Focusing on user needs and continuously improving our platform based on their feedback and emerging research." />
    </div>
  </div>
);

const About: React.FC = () => (
  <div className="about-container">
    <Vision />
    <Mission />
    <Values />
  </div>
);

export default About;

import React, { useState, useEffect } from "react";
import styles from "./countdown.module.css";

const CountdownTimer: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [isLaunched, setIsLaunched] = useState(false);

  // Set the target date explicitly in UTC
  const targetDateUTC = new Date(Date.UTC(2024, 11, 16, 15, 0, 0));

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDateUTC.getTime() - now.getTime();

      //console.log('difference: ', difference);
      //console.log('time now: ', now.toISOString());

      if (difference <= 0) {
        clearInterval(interval);
        setIsLaunched(true);
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({
        days: String(days).padStart(2, "0"),
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDateUTC]);


  return (
    <div className={styles.countdownContainer}>
      <div className={styles.main}>
        <div className={styles.text}>
          {isLaunched ? "It's official, we've launched!" : "Launching Soon"}
        </div>
        {!isLaunched && (
          <div className={styles.timerContainer}>
            <div className={styles.timer}>
              <span className={styles.time}>{timeLeft.days}</span>
              <span className={styles.label}>Days</span>
            </div>
            <span className={styles.colon}>:</span>
            <div className={styles.timer}>
              <span className={styles.time}>{timeLeft.hours}</span>
              <span className={styles.label}>Hours</span>
            </div>
            <span className={styles.colon}>:</span>
            <div className={styles.timer}>
              <span className={styles.time}>{timeLeft.minutes}</span>
              <span className={styles.label}>Minutes</span>
            </div>
            <span className={styles.colon}>:</span>
            <div className={styles.timer}>
              <span className={styles.time}>{timeLeft.seconds}</span>
              <span className={styles.label}>Seconds</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountdownTimer;

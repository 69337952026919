// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown_countdownContainer__fM4OH {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
}

.countdown_main__eXOhY {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.countdown_text__eqA\\+l {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #edfbfa;
  padding-right: 20px;
}

.countdown_timerContainer__oWah5 {
  display: flex;
  align-items: center;
}

.countdown_timer__zGPXe {
  display: flex;
  font-size: 2rem;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px 8px;
  border: 2px solid #63DE77;
  border-radius: 5px;
  margin: 0 5px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.countdown_time__NbrMo {
  font-size: 2.5rem;
  font-weight: bold;
  color: #edfbfa;
}

.countdown_label__FxUHR {
  font-size: 12px;
  text-transform: uppercase;
  color: #d0d0d0;
}

.countdown_colon__w90bs {
  font-size: 2.3rem;
  font-weight: bold;
  margin: 0 5px;
  color: #edfbfa;
}
`, "",{"version":3,"sources":["webpack://./src/components/misc/countdown.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,mBAAmB;EACnB,oCAAoC;EACpC,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,8CAA8C;AAChD;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,cAAc;AAChB","sourcesContent":[".countdownContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: none;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  color: white;\n}\n\n.main {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  text-align: center;\n}\n\n.text {\n  font-size: 1.5rem;\n  font-weight: bold;\n  margin-bottom: 10px;\n  color: #edfbfa;\n  padding-right: 20px;\n}\n\n.timerContainer {\n  display: flex;\n  align-items: center;\n}\n\n.timer {\n  display: flex;\n  font-size: 2rem;\n  flex-direction: column;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.1);\n  padding: 5px 8px;\n  border: 2px solid #63DE77;\n  border-radius: 5px;\n  margin: 0 5px;\n  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.time {\n  font-size: 2.5rem;\n  font-weight: bold;\n  color: #edfbfa;\n}\n\n.label {\n  font-size: 12px;\n  text-transform: uppercase;\n  color: #d0d0d0;\n}\n\n.colon {\n  font-size: 2.3rem;\n  font-weight: bold;\n  margin: 0 5px;\n  color: #edfbfa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countdownContainer": `countdown_countdownContainer__fM4OH`,
	"main": `countdown_main__eXOhY`,
	"text": `countdown_text__eqA+l`,
	"timerContainer": `countdown_timerContainer__oWah5`,
	"timer": `countdown_timer__zGPXe`,
	"time": `countdown_time__NbrMo`,
	"label": `countdown_label__FxUHR`,
	"colon": `countdown_colon__w90bs`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import config from "../../config";
import styles from "./mailinglist.module.css";

interface MailingListPopupProps {
  onClose: () => void; // Callback to close the popup
}

const MailingListPopup: React.FC<MailingListPopupProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<"success" | "exists" | "error" | "">("");

  const handleAddToMailingList = async () => {
    setLoading(true);
    setMessage("");
    setMessageType("");

    try {
      const response = await fetch(`${config.base_url}/api/mailinglist/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const result = await response.json();

        switch (result.status) {
          case "success":
            setMessageType("success");
            setMessage("Successfully subscribed!");
            setEmail("");
            break;
          case "exists":
            setMessageType("exists");
            setMessage("You are already subscribed to the mailing list.");
            break;
          case "error":
          default:
            setMessageType("error");
            setMessage(result.message || "There was an error adding you to the mailing list.");
            break;
        }
      } else {
        const error = await response.json();
        setMessageType("error");
        setMessage(`Failed to subscribe: ${error.error || "Unknown error"}`);
      }
    } catch (error) {
      setMessageType("error");
      setMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.popup}>
      <h3>Join Our Mailing List</h3>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className={styles.input}
        disabled={loading}
      />
      <button
        onClick={handleAddToMailingList}
        disabled={loading || !email}
        className={styles.button}
      >
        {loading ? "Subscribing..." : "Subscribe"}
      </button>
      <button onClick={onClose} className={styles.closeButton}>
        Close
      </button>
      {message && <p className={`${styles.message} ${styles[messageType]}`}>{message}</p>}
    </div>
  );
};

export default MailingListPopup;

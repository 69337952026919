import React, { useState } from 'react';
import Question from './Question';
import faqQuestions from './faq_questions.json';
import styles from './faq.module.css';

interface FAQQuestion {
  id: string;
  subject: string;
  question: string;
  title: string;
  content: string;
  placeholder?: boolean;
}

const FAQ: React.FC = () => {
  // Extract unique subjects from the JSON
  const subjects = ["All", ...Array.from(new Set(Object.values(faqQuestions).map((q) => q.subject)))];

  const [selectedSubject, setSelectedSubject] = useState<string>("All");

  // Add IDs dynamically while processing the questions
  const questionEntries: FAQQuestion[] = Object.entries(faqQuestions).map(([id, q]) => ({
    id,
    ...q,
  }));

  const filteredQuestions = selectedSubject === "All"
    ? questionEntries
    : questionEntries.filter((question) => question.subject === selectedSubject);

  const chunkedQuestions: FAQQuestion[][] = [];
  for (let i = 0; i < filteredQuestions.length; i += 3) {
    chunkedQuestions.push(filteredQuestions.slice(i, i + 3));
  }

  const lastRow = chunkedQuestions[chunkedQuestions.length - 1];
  if (lastRow && lastRow.length < 3) {
    const placeholdersNeeded = 3 - lastRow.length;
    for (let i = 0; i < placeholdersNeeded; i++) {
      lastRow.push({
        id: `placeholder-${i}`,
        subject: '',
        question: '',
        title: '',
        content: '',
        placeholder: true,
      });
    }
  }

  return (
    <>
      {/* Subject selector dropdown outside the faqContainer */}
      <div className={styles.faqSelectorContainer}>
        <label htmlFor="subject-select">Filter by Subject: </label>
        <select
          id="subject-select"
          value={selectedSubject}
          onChange={(e) => setSelectedSubject(e.target.value)}
        >
          {subjects.map((subject, index) => (
            <option key={index} value={subject}>
              {subject}
            </option>
          ))}
        </select>
      </div>
  
      {/* FAQ container */}
      <div className={styles.faqContainer}>
        {/* Render FAQ rows with 3 questions per row */}
        {chunkedQuestions.map((row, rowIndex) => (
          <div className={styles.faqRow} key={rowIndex}>
            {row.map((question) =>
              question.placeholder ? (
                <div key={question.id} className={`${styles.questionBox} ${styles.empty}`} />
              ) : (
                <Question key={question.id} question_id={question.id} />
              )
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQ;


import React from 'react';
import styles from './getapp.module.css';

const GetApp: React.FC = () => {
  return (
    <div className={styles.contactusContainer}>
      <h1>How to get EMA</h1>
      <p>
        We are currently in beta testing, once the app is available check back here to see how to get it for your device!
        <br />
        <br />
        If you have any issues or questions getting the app, please contact us <a href="/contact">here</a>
      </p>
    </div>
  );
};

export default GetApp;

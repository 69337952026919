// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**********************************************
 ****              footer css              ****
 **********************************************/
.footer_footerContainer__--Xv7 {
  position: relative;
  color: #e6fcf8;
  width: 100%;
  bottom: 0;
  margin: 0 auto;
  font-size: .8rem;
}

.footer_footerContainer__--Xv7 h1 {
  color: #caf8ea;
  text-align: center;
}

.footer_footerContainer__--Xv7 p {
  color: #e6fcf8;
  text-align: center;
}

.footer_footerContainer__--Xv7 a {
  color: #16B99E;
  transition: color 0.4s ease; /* Smooth color change */
  display: inline-block; /* Ensures transform works */
  font-weight: 700;
}

.footer_footerContainer__--Xv7 a:hover {
  color: #aaf8ea;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.css"],"names":[],"mappings":"AAAA;;+CAE+C;AAC/C;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,SAAS;EACT,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,2BAA2B,EAAE,wBAAwB;EACrD,qBAAqB,EAAE,4BAA4B;EACnD,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/**********************************************\n ****              footer css              ****\n **********************************************/\n.footerContainer {\n  position: relative;\n  color: #e6fcf8;\n  width: 100%;\n  bottom: 0;\n  margin: 0 auto;\n  font-size: .8rem;\n}\n\n.footerContainer h1 {\n  color: #caf8ea;\n  text-align: center;\n}\n\n.footerContainer p {\n  color: #e6fcf8;\n  text-align: center;\n}\n\n.footerContainer a {\n  color: #16B99E;\n  transition: color 0.4s ease; /* Smooth color change */\n  display: inline-block; /* Ensures transform works */\n  font-weight: 700;\n}\n\n.footerContainer a:hover {\n  color: #aaf8ea;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": `footer_footerContainer__--Xv7`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./components/landing/Landing";
import AboutPage from "./components/about/AboutPage";
import FAQ from "./components/faq/FAQ";
import GetApp from "./components/getapp/GetApp";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import { Navbar } from './components/navbar';

const App: React.FC = () => {
  return (
  <Router>
    {/*<div className="pageContainer">*/}
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/get" element={<GetApp />} />
      </Routes>
    {/*</div>*/}
    <Footer />
  </Router>
  );
};

export default App;

// Legal.tsx

import React, { useState, useEffect } from 'react';
import styles from './about.module.css';
import ReactMarkdown from 'react-markdown';

interface LegalDocumentProps {
  file: string;
}

const LegalDocument: React.FC<LegalDocumentProps> = ({ file }) => {
  const [content, setContent] = useState<string>('Loading...');

  useEffect(() => {
    const loadDocument = async () => {
      try {
        //console.log('Loading legal document:', file);
        const response = await fetch(file);
        if (!response.ok) throw new Error('Network response was not ok');
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error('Error loading document:', error);
        setContent('Failed to load document.');
      }
    };

    loadDocument();
  }, [file]);

  return (
    <div className={styles.legalContent}>
      <ReactMarkdown>{content}</ReactMarkdown> {/* Render Markdown content */}
    </div>
  );
};

const Legal: React.FC = () => {
  const [activeDocument, setActiveDocument] = useState<'terms' | 'privacy' | 'cookies'>('terms');

  const getDocumentFile = (): string => {
    switch (activeDocument) {
      case 'privacy':
        return '/legal/privacyPolicy.md';
      case 'cookies':
        return '/legal/cookiePolicy.md';
      default:
        return '/legal/termsOfService.md';
    }
  };

  return (
    <div className={styles.legal}>
      <h1>Legal Information</h1>
      <div className={styles.legalButtons}>
        <button onClick={() => setActiveDocument('terms')}>Terms of Service</button>
        <button onClick={() => setActiveDocument('privacy')}>Privacy Policy</button>
        <button onClick={() => setActiveDocument('cookies')}>Cookie Policy</button>
      </div>

      <div className={styles.legalContentContainer}>
        <div className="animate__animated animate__fadeIn">
          <LegalDocument file={getDocumentFile()} />
        </div>
      </div>
    </div>
  );
};

export default Legal;

